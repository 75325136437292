@import '../default/variables';

// override any variable below

$publication-banner-logo-small-path: '/design/source/images/asc-logo.svg';
$paid-article-logo: '/design/source/images/wb+_gruen.svg';
$paywall-plus-logo: '/design/source/images/logo-wb-plus.svg';
$paywall-logo-flyin: '/design/source/images/logo-wb.png';
$paywall-logo: '/design/source/images/logo-wb-metered.svg';

// Color Variables
$color-primary: #2d8a3a;
$text-color: #323232;
$light-gray: #f2f2f2;
$gray: #ccc;
$dark-gray: #a6a6a6;
$special-color-1: #f6921e;
$special-color-2: #e6f7e9;
$pw-background: #f2f2f2;

// Typography Variables
$font-primary: 'Lato', sans-serif;
